// Color scheme for light and dark modes.

$light: #3C6080;
$dark: #4B7DB9;


$light-header: #55555c;
$dark-header: #B0D0E3;

// Highlighting color for code block borders and language name.

$code-light: #ff0066;
$code-dark: #d0f6db;

$message-green: #62bb47;
$message-yellow: #fcb827;
$message-orange: #f6821f;
$message-red: #e03940;
$message-purple: #953c96;
$message-blue: #009ddc;

$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$code-font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$root-font-size: 16px;
$root-line-height: 1.5;

$body-color: rgba(0,0,0,.8);
$body-bg: rgba(0,0,0,.03);
$body-color-dark: hsla(0,0%,100%,.8);
$body-bg-dark: rgba(0,0,0,.9);

$large-breakpoint: 38em;
$large-font-size: 20px;
