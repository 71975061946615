// Masthead
//
// The style of the site's title and optional secondary description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  text-align: left;
  font-size: 1.25rem;
}

.masthead-title {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  color: $body-color;
  position: relative;
  text-align: left;
  padding-right: 12px;
  


@media (prefers-color-scheme: dark) {
  color: $body-color-dark;
  }

  a {
    color: inherit;
  }

  small {
    font-size: 55%;
    font-weight: 400;
    opacity: .5;
  }
}

.navigation {
  display: block;
  font-size: 100%;
  font-weight: 500;
  color: $body-color;
  
@media (prefers-color-scheme: dark) {
  color: $body-color-dark;
  }
  
ul#navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
  }
  ul#navigation-list li {
    display: inline;
    list-style: none;
	padding-left: 0;
    padding-right: 12px;
	margin: 0;
    }
}
