// Site structure

.container {
  max-width: 35rem;
  padding-left:  1.75rem;
  padding-right: 1.75rem;
  margin-left:  auto;
  margin-right: auto;
}

footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  font-size: 60%;
  display: flex;
  justify-content: between;
}

.copyright {
	width: 70%;
}
.contact-info {
	text-align: right;
	width: 30%;
	float: right;
}
