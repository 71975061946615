// Body

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $root-font-family;
  font-size: $root-font-size;
  line-height: $root-line-height;

  @media (min-width: $large-breakpoint) {
    font-size: $large-font-size;
  }
}

body {
  color: $body-color;
  background-color: $body-bg;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
	  
@media (prefers-color-scheme: dark) {
  color: $body-color-dark;
  background-color: $body-bg-dark;
  }
}

// No `:visited` state is required by default (browsers will use `a`)
a {
  color: $light;
  font-weight: 500;
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: none;
	color: $light;
	
	@media (prefers-color-scheme: dark) {
    color: $dark;
    }
  }

  strong {
    color: inherit;
  }
  
@media (prefers-color-scheme: dark) {
  color: $dark;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #d4d4d4;
  border-collapse: collapse;
  
@media (prefers-color-scheme: dark) {
  border: 1px solid #555555;
  }
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #d4d4d4;
  
@media (prefers-color-scheme: dark) {
  border: 1px solid #555555;
  }
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #eee;

@media (prefers-color-scheme: dark) {
  background-color: #2d2d2d;
  }
}
